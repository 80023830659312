<template>
  <v-alert outlined max-width="841" class="view-alert success-view" data-test="success-view">
    <v-container>
      <v-row>
        <v-col> <h2 class="title success-title" v-html="$t('success_view.title')"></h2> </v-col>
      </v-row>
      <v-row
        ><v-col> <p v-html="$t('success_view.description')"></p> </v-col
      ></v-row>
      <v-row>
        <v-col class="id-wrap">
          <label-text :label="$t('success_view.change_request_id')" :text="changeRequestId" data-test="success_view-change-request-id"></label-text> <button class="clipboard" v-clipboard:copy="changeRequestId"><i class="fal fa-copy"></i></button>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="col"> <p class="mb-0" v-html="$t('success_view.questions')"></p> </v-col>
      </v-row>
    </v-container>
  </v-alert>
</template>

<script>
import { renderErrorTrackable } from '@/mixins';
import { mapGetters } from 'vuex';
import _get from 'lodash/get';

export default {
  name: 'success-view',
  mixins: [renderErrorTrackable],
  props: {
    changeRequestId: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters('partner', ['partner']),
    ...mapGetters('ui', ['currentLocale']),
    partnerUrl() {
      return _get(this.partner, `content.program_url.${this.currentLocale}`, '');
    },
  },
};
</script>
