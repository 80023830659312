import { ref } from '@vue/composition-api';
import _get from 'lodash/get';
import { transformGeneralError } from '@/utils/error/error-transformer';

function createDefaultContext(contextOverwrite = {}) {
  function defaultHookFunction() {}

  return {
    action: contextOverwrite.action || null,
    name: contextOverwrite.name || 'global',
    success: contextOverwrite.success || defaultHookFunction,
    beforeError: contextOverwrite.beforeError || defaultHookFunction,
    afterError: contextOverwrite.afterError || defaultHookFunction,
    finally: contextOverwrite.finally || defaultHookFunction,
  };
}

export default function safeExecute(store) {
  const working = ref(false);

  const internalExecute = async (context, params) => {
    let result;
    let ctx = createDefaultContext(context);
    try {
      let actionResult = await store.dispatch(ctx.action, params, { root: true });

      // Post success hook
      if (ctx.success) {
        result = (await ctx.success(actionResult)) || actionResult;
      }
    } catch (err) {
      let error;

      //Convert Store Error
      if (!err.errorType) {
        error = transformGeneralError(err, 'store');
      } else {
        error = err;
      }

      // Pre Error Add Hook
      if (ctx.beforeError) {
        result = (await ctx.beforeError(error)) || error;
      }

      await store.dispatch('ui/addError', { contextName: _get(ctx, 'name', 'global'), error: error }, { root: true });

      // After Error Add Hook
      if (ctx.afterError) {
        result = (await ctx.afterError(error)) || error;
      }
    } finally {
      // Finally Hook
      if (ctx.finally) {
        result = (await ctx.finally()) || result;
      }
    }
    return result;
  };

  const execute = async (context = {}, params) => {
    await store.dispatch('ui/clearError', 'global', { root: true });
    await store.dispatch('ui/clearError', context.name, { root: true });
    let result;

    try {
      working.value = true;
      result = await internalExecute(context, params);
    } catch (err) {
      result = await store.dispatch('ui/addError', { contextName: 'global', error: err }, { root: true });
    } finally {
      working.value = false;
    }

    return result;
  };

  return { working, createDefaultContext, execute, internalExecute };
}
