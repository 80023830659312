export default ({ Vue }) => {
  const fakeRecaptchaInstance = {
    hideBadge: () => {
      console.warn(`OVERRIDING METHOD $recaptchaInstance.hideBadge`); // eslint-disable-line no-console
    },
    showBadge: () => {
      console.warn(`OVERRIDING METHOD $recaptchaInstance.showBadge`); // eslint-disable-line no-console
    },
  };

  function fakeRecaptchaLoaded() {
    console.warn(`OVERRIDING METHOD $recaptchaLoaded`); // eslint-disable-line no-console
  }

  function fakeRecaptcha() {
    console.warn(`OVERRIDING METHOD $recaptcha`); // eslint-disable-line no-console
    return 'HELLO_FAKE_RECAPTCHA';
  }

  console.warn('WARNING - LOADING FAKE RECAPTCHA'); // eslint-disable-line no-console
  console.warn('OVERRIDING $recaptchaInstance'); // eslint-disable-line no-console
  Vue.prototype.$recaptchaInstance = fakeRecaptchaInstance;

  console.warn('OVERRIDING $recaptchaLoaded'); // eslint-disable-line no-console
  Vue.prototype.$recaptchaLoaded = fakeRecaptchaLoaded;

  console.warn('OVERRIDING $recaptcha'); // eslint-disable-line no-console
  Vue.prototype.$recaptcha = fakeRecaptcha;
};
