<template>
  <v-dialog v-model="showDialog" persistent max-width="1024px">
    <v-card class="basic-modal-styles modal-confirm">
      <v-card-title class="modal-title">
        <h2 class="modal-confirm--title">{{ title }}</h2>
      </v-card-title>

      <v-card-text class="modal-text-section">
        <error :context="context" />
        <p class="modal-text">{{ paragraph1 }}</p>
        <p class="modal-text">
          {{ paragraph2 }} <a class="modal-confirm--url" :href="`https:${url}`" target="_blank">{{ url }}</a>
        </p>
        <div><slot name="fields"></slot></div>
      </v-card-text>

      <v-card-actions class="modal-actions">
        <button-modal-cancel :text="cancel_text" @click="cancel" class="modal-cancel-button"></button-modal-cancel>
        <button-success @click="submit" :is-disabled="submit_btn_disabled" :text="submit_text" :loading="submit_working" class="modal-submit-button" data-test="confirm-modal--submit-button"></button-success>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import error from '@/mixins/action-error-trackable';

export default {
  name: 'modal-confirm-personal-info-changes',
  mixins: [error],
  props: {
    title: {
      type: String,
      required: true,
    },
    context: {
      type: String,
      required: true,
    },
    paragraph1: {
      type: String,
      required: true,
    },
    paragraph2: {
      type: String,
    },
    url: {
      type: String,
    },
    cancel_text: {
      type: String,
      required: true,
    },
    submit_text: {
      type: String,
      required: false,
    },
    showModal: {
      type: Boolean,
      required: true,
      default: false,
    },
    submit_working: {
      type: Boolean,
      required: false,
      default: false,
    },
    submit_btn_disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      // Important for the reactivity if clicking out of the dialog box closes the modal
      showDialog: this.showModal,
    };
  },
  watch: {
    showModal: function (newVal) {
      // Important for the reactivity if clicking out of the dialog box closes the modal
      this.showDialog = newVal;
    },
    showDialog: function (newVal) {
      // Important for the reactivity if clicking out of the dialog box closes the modal
      if (newVal === false) {
        this.$emit('close');
      }
    },
  },
  methods: {
    clear() {
      this.clearError(this.context);
    },
    submit() {
      this.clear();
      this.$emit('submit');
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
@import './../../../scss/utilities/custom-variables';

.modal-confirm--title {
  margin: 0 auto;
}
.modal-confirm .modal-submit-button.v-btn {
  background-color: #da4545 !important;
}
.theme--light.v-application a.modal-confirm--url {
  color: $primary;
  font-weight: normal;
}
</style>
