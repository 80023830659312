<template>
  <v-dialog scrollable v-model="showDialog" max-width="1024px">
    <v-card class="basic-modal-styles modal-scrollable">
      <v-card-title class="modal-title">
        <h3>{{ title }}</h3>
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text class="scrollable-zone">
        <error :context="context" />
        <p class="modal-text" v-html="text"></p>
        <div><slot name="fields"></slot></div>
      </v-card-text>

      <v-card-actions class="modal-actions">
        <button-modal-cancel :text="cancel_text" @click="cancel" class="modal-cancel-button"></button-modal-cancel>
        <v-spacer></v-spacer>
        <button-success @click="submit" :is-disabled="submit_btn_disabled" :text="submit_text" :loading="submit_working" class="modal-submit-button" data-test="modal-scrollable--submit-button"></button-success>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import error from '@/mixins/action-error-trackable';

export default {
  name: 'modal-scrollable-zone',
  mixins: [error],
  props: {
    title: {
      type: String,
      required: true,
    },
    context: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    cancel_text: {
      type: String,
      required: true,
    },
    submit_text: {
      type: String,
      required: false,
    },
    showModal: {
      type: Boolean,
      required: true,
      default: false,
    },
    submit_working: {
      type: Boolean,
      required: false,
      default: false,
    },
    submit_btn_disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      // Important for the reactivity if clicking out of the dialog box closes the modal
      showDialog: this.showModal,
    };
  },
  watch: {
    showModal: function (newVal) {
      // Important for the reactivity if clicking out of the dialog box closes the modal
      this.showDialog = newVal;
    },
    showDialog: function (newVal) {
      // Important for the reactivity if clicking out of the dialog box closes the modal
      if (newVal === false) {
        this.$emit('close');
      }
    },
  },
  methods: {
    clear() {
      this.clearError(this.context);
    },
    submit() {
      this.clear();
      this.$emit('submit');
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
  },
};
</script>
