<template>
  <v-btn outlined color="error" v-on="listeners" v-bind="$attrs" :loading="loading">{{ text }}</v-btn>
</template>

<script>
export default {
  name: 'button-error',
  props: {
    text: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        button: (event) => this.$emit('button', event.target.value),
      };
    },
  },
};
</script>
