import Vue from 'vue';
import VueI18n from 'vue-i18n';
import messages from '../i18n';

const defaultLocale = (navigator.language || navigator.userLanguage).split('-')[0];

Vue.use(VueI18n);

export const i18n = new VueI18n({
  locale: defaultLocale,
  fallbackLocale: 'en',
  messages,
});

export default ({ app, Vue, store }) => {
  app.i18n = i18n;
};
