import * as VeeValidate from 'vee-validate/dist/vee-validate.full';
import _merge from 'lodash/merge';
import _omit from 'lodash/omit';
import { i18n } from '@/plugins/i18n';

export default {
  namespaced: true,
  state: {
    errors: {},
    currentLocale: 'en',
    locales: [
      {
        text: 'EN',
        value: 'en',
      },
      {
        text: 'FR',
        value: 'fr',
      },
    ],
  },
  getters: {
    error: (state) => state.errors,
    currentLocale: (state) => state.currentLocale,
    locales: (state) => state.locales,
  },
  mutations: {
    currentLocale(state, locale) {
      state.currentLocale = locale;
    },
    error(state, { contextName, error }) {
      if (error) {
        if (state.errors[contextName]) {
          console.warn(`Only the first error will be displayed.  Caught: ${error}`); // eslint-disable-line no-console
        } else {
          state.errors = _merge({}, state.errors, { [contextName]: error });
        }
      } else if (state.errors[contextName]) {
        state.errors = _merge({}, _omit(state.errors, contextName));
      }
    },
    clearError(state) {
      state.errors = {};
    },
  },
  actions: {
    changeLocale({ commit }, newLocale) {
      let setNewLocale = 'en';
      if (newLocale) {
        setNewLocale = newLocale.toLowerCase();
      }
      if (setNewLocale !== 'fr' && setNewLocale !== 'en') {
        setNewLocale = 'en';
      }

      i18n.locale = setNewLocale;
      VeeValidate.localize(setNewLocale);
      commit('currentLocale', setNewLocale);
    },
    addError({ commit }, { contextName, error }) {
      console.log(`CATCHED ERROR. Context: ${contextName} Error: `, error); // eslint-disable-line no-console
      commit('error', { contextName, error });
    },
    clearError({ commit }, contextName) {
      commit('error', { contextName, error: null });
    },
    clearAllError({ commit }) {
      commit('clearError');
    },
    init({ dispatch }) {
      const defaultLocale = (navigator.language || navigator.userLanguage).split('-')[0];
      dispatch('changeLocale', defaultLocale);
    },
  },
};
