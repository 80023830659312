function HttpError(status, data) {
  Error.call(this);
  this.errorType = 'http';

  this.code = `errors.${this.errorType}.${data.error.code.replaceAll('.', '_')}`;
  this.response_code = data.error.code;
  this.type = data.error.title;
  this.msg = data.error.detail;
  this.stack = Error().stack;
  this.status_code = status;

  return this;
}

function SystemError(status, data) {
  Error.call(this);
  this.errorType = 'system';

  this.code = `errors.${this.errorType}.${data.error.code.replaceAll('.', '_')}`;
  this.response_code = data.error.code;
  this.type = data.error.title;
  this.msg = data.error.detail;
  this.status_code = status;
  this.stack = Error().stack;

  return this;
}

function AppError(err, vm, info) {
  Error.call(this);

  this.errorType = 'app';

  this.code = `errors.${this.errorType}`;
  this.msg = err.message;
  this.stack = Error().stack;
  return this;
}

require('util').inherits(SystemError, Error);
require('util').inherits(HttpError, Error);
require('util').inherits(AppError, Error);

export default {
  SystemError,
  HttpError,
  AppError,
};
