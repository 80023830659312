import Vue from 'vue';
import Errors from './../utils/error/error-types';

const service = {
  async getApplicationValues(payload) {
    const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/public/selfserve/v1/change-requests/application/values`, payload);
    return response.data.data;
  },
  async requestApplicationChanges(payload) {
    try {
      const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/public/selfserve/v1/change-requests/application/change`, payload);
      return response.data.data;
    } catch (e) {
      if (isValidationError(e)) {
        return Promise.reject(transformError(400, 'selfserve.request.application.changes.validation'));
      } else if (isRecaptchaError(e)) {
        return Promise.reject(transformError(400, 'selfserve.request.application.changes.recaptcha.failed'));
      }
      throw e;
    }
  },
};

function transformError(statusCode, code) {
  return new Errors.HttpError(statusCode, { error: { code } });
}

function isValidationError(e) {
  return e.response_code === 'validation.error';
}

function isRecaptchaError(e) {
  return e.response_code === 'recaptcha.failed';
}

export default service;
