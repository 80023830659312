const FAKE_PLUGINS = {
  FAKE_RECAPTCHA: 'fake_recaptcha',
};

const FAKE_ENV = {
  [FAKE_PLUGINS.FAKE_RECAPTCHA]: 'VUE_APP_RECAPTCHA_CAN_USE_FAKE',
};

const FAKE_QUERY = {
  [FAKE_PLUGINS.FAKE_RECAPTCHA]: 'useFakeRecaptcha',
  default: 'useFake',
};

function canUseFakeService(service) {
  if (process.env.NODE_ENV === 'production') {
    return false;
  }

  const canUseService = JSON.parse(process.env[FAKE_ENV[service]] || 'false');
  return canUseService;
}

function useFakeService(service) {
  if (!canUseFakeService(service)) {
    return false;
  }

  const parameters = new URLSearchParams(window.location.search);
  const defaultUseFake = JSON.parse(parameters.get(FAKE_QUERY.default) || 'false');
  const serviceUseFake = JSON.parse(parameters.get(FAKE_QUERY[service]) || 'false');

  return defaultUseFake || serviceUseFake;
}

export function useFakeRecaptcha() {
  return useFakeService(FAKE_PLUGINS.FAKE_RECAPTCHA);
}
