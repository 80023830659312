<template>
  <v-row>
    <v-col class="pt-0 pb-0">
      <validation-observer ref="observer" v-slot="{ valid }" slim>
        <v-form @submit.prevent="submit">
          <v-container class="pr-0 pl-0">
            <v-row>
              <v-col cols="12" sm="4" md="3" class="col">
                <hello-validation-provider ref="validator" rules="required|max:20" :name="$t('personal_info_change.fields.first_name')">
                  <template v-slot="{ validationState, errors }">
                    <v-text-field
                      :disabled="!requested_changes.first_name_verification_required"
                      type="text"
                      autocomplete="new-first_name"
                      ref="first_field"
                      v-model="currentCardApplicant.first_name"
                      counter
                      :error="!validationState"
                      :error-messages="errors"
                      maxlength="20"
                      :label="$t('personal_info_change.fields.first_name') + ' *'"
                      data-test="personal_info_change--first-name"
                    ></v-text-field>
                  </template>
                </hello-validation-provider>
              </v-col>
              <v-col cols="12" sm="4" md="3" class="col">
                <hello-validation-provider rules="required|max:20" :name="$t('personal_info_change.fields.last_name')">
                  <template v-slot="{ validationState, errors }">
                    <v-text-field
                      :disabled="!requested_changes.last_name_verification_required"
                      autocomplete="new-last_name"
                      v-model="currentCardApplicant.last_name"
                      counter
                      :error="!validationState"
                      :error-messages="errors"
                      maxlength="20"
                      :label="$t('personal_info_change.fields.last_name') + ' *'"
                      data-test="personal_info_change--last-name"
                    ></v-text-field>
                  </template>
                </hello-validation-provider>
              </v-col>
              <v-col cols="12" sm="4" md="3" class="col" v-if="requested_changes.dob_verification_required">
                <hello-validation-provider rules="required|max:10" :name="$t('personal_info_change.fields.birthdate')">
                  <template v-slot="{ validationState, errors }">
                    <hello-date-picker
                      v-model="currentCardApplicant.date_of_birth"
                      :error="!validationState"
                      :error-messages="errors"
                      :locale="currentLocale"
                      :label="$t(`personal_info_change.fields.birthdate`) + ' *'"
                      :max-date="maxBirthdate()"
                      :min-date="minBirthdate()"
                      :maxlength="10"
                      min-width="290px"
                    />
                  </template>
                </hello-validation-provider>
              </v-col>
            </v-row>
            <h4 v-if="requested_changes.address_verification_required">{{ $t('personal_info_change.fields.address') }}</h4>
            <v-row>
              <v-col cols="12" sm="9" class="col" v-if="requested_changes.address_verification_required">
                <hello-validation-provider rules="required|max:50" :name="$t('personal_info_change.fields.street')">
                  <template v-slot="{ validationState, errors }">
                    <v-text-field
                      type="text"
                      autocomplete="new-street"
                      v-model="currentCardApplicant.street"
                      counter
                      :error="!validationState"
                      :error-messages="errors"
                      maxlength="50"
                      :label="$t('personal_info_change.fields.street') + ' *'"
                      data-test="personal_info_change--street"
                    ></v-text-field>
                  </template>
                </hello-validation-provider>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="4" md="3" class="col" v-if="requested_changes.address_verification_required">
                <hello-validation-provider rules="required|max:50" :name="$t('personal_info_change.fields.city')">
                  <template v-slot="{ validationState, errors }">
                    <v-text-field
                      type="text"
                      autocomplete="new-city"
                      v-model="currentCardApplicant.city"
                      counter
                      :error="!validationState"
                      :error-messages="errors"
                      maxlength="50"
                      :label="$t('personal_info_change.fields.city') + ' *'"
                      data-test="personal_info_change--city"
                    >
                    </v-text-field>
                  </template>
                </hello-validation-provider>
              </v-col>
              <v-col cols="12" sm="4" md="3" class="col" v-if="requested_changes.address_verification_required">
                <hello-validation-provider rules="required|max:2" :name="$t('personal_info_change.fields.state')">
                  <template v-slot="{ validationState, errors }">
                    <v-select
                      autocomplete="new-state"
                      v-model="currentCardApplicant.state"
                      :error="!validationState"
                      :error-messages="errors"
                      :label="$t(`personal_info_change.fields.state`) + ' *'"
                      :items="stateOptions"
                      item-text="name"
                      item-value="abbr"
                      data-test="personal_info_change--state"
                    ></v-select>
                  </template>
                </hello-validation-provider>
              </v-col>
              <v-col cols="12" sm="4" md="3" class="col" v-if="requested_changes.address_verification_required">
                <hello-validation-provider rules="required|length:6|zip" name="zip">
                  <template v-slot="{ validationState, errors }">
                    <hello-masked-text-field
                      type="text"
                      autocomplete="new-zip"
                      v-model="currentCardApplicant.zip"
                      :error="!validationState"
                      :error-messages="errors"
                      hint="X9X 9X9"
                      :label="$t('personal_info_change.fields.zip') + ' *'"
                      the-mask="A#A #A#"
                      data-test="personal_info_change--postal-code"
                    ></hello-masked-text-field>
                  </template>
                </hello-validation-provider>
              </v-col>
            </v-row>
            <v-row dense class="pt-6" v-if="documentsRequested">
              <v-col>
                <h4>{{ $t('personal_info_change.documents_section.title') }}</h4>
              </v-col>
            </v-row>
            <v-row dense v-if="documentRequested">
              <v-col cols="12" sm="6">
                <hello-validation-provider :rules="'file_size:' + maxFileSizeInBytes" name="file_size">
                  <template v-slot="{ validationState, errors }">
                    <v-file-input
                      v-model="document1"
                      :error="!validationState"
                      :error-messages="errors"
                      accept=".pdf, .jpg, .jpeg, .png"
                      truncate-length="100"
                      :label="$t(`personal_info_change.documents_section.upload_document`)"
                      show-size
                      dense
                    ></v-file-input>
                  </template>
                </hello-validation-provider>
              </v-col>
            </v-row>
            <v-row dense v-if="documentsRequested">
              <v-col cols="12" sm="6">
                <hello-validation-provider :rules="'file_size:' + maxFileSizeInBytes" name="file_size">
                  <template v-slot="{ validationState, errors }">
                    <v-file-input v-model="document2" :error="!validationState" :error-messages="errors" accept=".pdf, .jpg, .jpeg, .png" truncate-length="100" :label="$t(`personal_info_change.documents_section.upload_document`)" show-size dense>
                    </v-file-input>
                  </template>
                </hello-validation-provider>
              </v-col>
            </v-row>
            <button-success :loading="workingOverall" :is-disabled="!valid" @click="confirm" class="mt-5" :text="$t('personal_info_change.submit_button')" data-test="personal_info_change--button-success" />
          </v-container>
        </v-form>
      </validation-observer>
      <modal-confirm-personal-info-changes
        :show-modal="showConfirmModal"
        :title="$t(`personal_info_change.confirm.${confirmModalCase}.title`)"
        context="PersonalInfoChangeRequest"
        :paragraph1="$t(`personal_info_change.confirm.${confirmModalCase}.paragraph1`)"
        :paragraph2="$t(`personal_info_change.confirm.${confirmModalCase}.paragraph2`)"
        :url="$t(`personal_info_change.confirm.${confirmModalCase}.url`)"
        :cancel_text="$t(`personal_info_change.confirm.${confirmModalCase}.button_cancel`)"
        :submit_text="$t(`personal_info_change.confirm.${confirmModalCase}.button_submit`)"
        :submit_working="working"
        @submit="submit"
        @close="closeConfirmModal"
      ></modal-confirm-personal-info-changes>
    </v-col>
  </v-row>
</template>

<script>
import Jimp from 'jimp';

import moment from 'moment';
import { mapGetters } from 'vuex';
import { recaptcha } from '@/mixins';
import safeExecute from '@/composables/safe-execute';
import _get from 'lodash/get';
import axios from 'axios';
import personalInfoChangeFormUtil from '../../utils/ui/personal-info-change-form-util';

export default {
  setup(props, { root }) {
    const { execute, working } = safeExecute(root.$store);
    return {
      execute,
      working,
    };
  },
  name: 'personal-info-change-form',
  mixins: [recaptcha],
  props: {
    jwtToken: {
      type: String,
    },
    application: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      menu: false,
      showUploadDocumentModal: false,
      currentCardApplicant: mapCardApplicant(this.application),
      stateOptions: this.getStateOptions(),
      maxFileSizeInBytes: 5000000,
      document1: undefined,
      document2: undefined,
      showConfirmModal: false,
      confirmModalCase: 'loading',
      workingOverall: false,
    };
  },
  async created() {
    this.recaptcha('personalInfoChangeRequestSubmit');
  },
  watch: {
    application() {
      this.currentCardApplicant = mapCardApplicant(this.application);
    },
  },
  computed: {
    ...mapGetters('ui', ['currentLocale']),
    ...mapGetters('application', ['change_request_id', 'requested_changes']),
    ...mapGetters('selfserve', ['change_application_request_status', 'delete_documents_res_original_count', 'delete_documents_res_deleted_count']),
    documentRequested() {
      return this.requested_changes.document_for_address_required || this.requested_changes.document_for_dob_required;
    },
    documentsRequested() {
      return this.requested_changes.document_for_address_required && this.requested_changes.document_for_dob_required;
    },
  },
  methods: {
    async upload(file) {
      const formData = new FormData();

      const compressedFile = await this.compressImage(file);

      formData.append('file', compressedFile);
      formData.append('filename', compressedFile.name);
      formData.append('content_type', compressedFile.type || 'application/pdf');
      formData.append('token', this.jwtToken);
      formData.append('recaptcha_token', await this.recaptcha('personalInfoChangeRequestUploadDocument'));

      return axios.post(`${process.env.VUE_APP_BASE_API_URL}/public/selfserve/v1/change-requests/application/documents`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    },

    async compressImage(file) {
      const supportedTypes = ['image/gif', 'image/jpeg', 'image/png'];

      return new Promise((resolve) => {
        if (!supportedTypes.includes(file.type)) {
          return resolve(file);
        }

        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.addEventListener(
          'load',
          () => {
            // convert image file to base64 string

            Jimp.read(reader.result).then((sourceImage) => {
              let image = sourceImage;

              if (image.bitmap.width > 1920) {
                image = image.resize(1920, Jimp.AUTO);
              } else if (image.bitmap.height > 1080) {
                image = image.resize(Jimp.AUTO, 1080);
              }

              image = image.quality(50);

              image.getBufferAsync(file.type).then((buffer) => {
                image.getBase64Async(file.type).then((base64) => {
                  const newFile = new File([buffer], file.name, { type: file.type });

                  newFile.dataURL = base64;
                  newFile.height = image.bitmap.height;
                  newFile.width = image.bitmap.width;

                  resolve(newFile);
                });
              });
            });
          },
          false,
        );
      });
    },

    getStateOptions() {
      return [
        { name: this.$t('state.ab'), abbr: 'AB' },
        { name: this.$t('state.bc'), abbr: 'BC' },
        { name: this.$t('state.mb'), abbr: 'MB' },
        { name: this.$t('state.nb'), abbr: 'NB' },
        { name: this.$t('state.nl'), abbr: 'NL' },
        { name: this.$t('state.ns'), abbr: 'NS' },
        { name: this.$t('state.nt'), abbr: 'NT' },
        { name: this.$t('state.nu'), abbr: 'NU' },
        { name: this.$t('state.on'), abbr: 'ON' },
        { name: this.$t('state.pe'), abbr: 'PE' },
        { name: this.$t('state.qc'), abbr: 'QC' },
        { name: this.$t('state.sk'), abbr: 'SK' },
        { name: this.$t('state.yt'), abbr: 'YT' },
      ];
    },
    maxBirthdate() {
      return moment().subtract(18, 'years').toISOString().substr(0, 10);
    },
    minBirthdate() {
      return moment().subtract(140, 'years').toISOString().substr(0, 10);
    },
    constructPayload() {
      const payload = {
        first_name: this.currentCardApplicant.first_name,
        last_name: this.currentCardApplicant.last_name,
        date_of_birth: this.currentCardApplicant.date_of_birth,
        address: {
          street: this.currentCardApplicant.street,
          city: this.currentCardApplicant.city,
          state: this.currentCardApplicant.state,
          zip: this.$options.filters.formatPostalCode(this.currentCardApplicant.zip),
        },
      };

      this.document1 || this.document2 ? (payload.documents_added = true) : (payload.documents_added = false);

      return payload;
    },
    openUploadDocumentModal() {
      this.showUploadDocumentModal = true;
    },
    closeUploadDocumentModal() {
      this.showUploadDocumentModal = false;
    },
    async clearDocuments() {
      await this.execute(
        {
          name: 'applicationView',
          action: 'selfserve/deleteDocuments',
          success: this.checkResultOfClearDocuments,
        },
        {
          token: this.jwtToken,
          recaptcha_token: await this.recaptcha('personalInfoChangeRequestClearDocument'),
        },
      );
    },
    async checkResultOfClearDocuments() {
      if (this.delete_documents_res_deleted_count < this.delete_documents_res_original_count) {
        console.log('There was a problem clearing the database prior to upload. Surplus documents may have been saved'); // eslint-disable-line no-console
      }
    },
    openConfirmModal() {
      this.showConfirmModal = true;
    },
    closeConfirmModal() {
      this.showConfirmModal = false;
    },
    getOriginalValues() {
      const originalValues = mapCardApplicant(this.application);
      const cleaned_zip = originalValues.zip.trim().replace(' ', '');
      originalValues.zip = cleaned_zip;

      return originalValues;
    },
    async confirm() {
      const isValid = await this.$refs.observer.validate();

      if (isValid) {
        const form_state = personalInfoChangeFormUtil.state_of_changes(this.getOriginalValues(), this.currentCardApplicant, this.requested_changes, [this.document1, this.document2]);

        if (form_state === 'missing_two_docs') {
          this.confirmModalCase = 'two_docs';
          this.openConfirmModal();
        } else if (form_state === 'missing_one_doc') {
          this.confirmModalCase = 'one_doc';
          this.openConfirmModal();
        } else if (form_state === 'changes_required') {
          this.confirmModalCase = 'changes_required';
          this.openConfirmModal();
        } else {
          return this.submit();
        }
      }
    },
    async submit() {
      const isValid = await this.$refs.observer.validate();

      if (isValid) {
        this.workingOverall = true;
        try {
          await this.clearDocuments();

          if (this.document1) {
            await this.upload(this.document1);
          }
          if (this.document2) {
            await this.upload(this.document2);
          }
          await this.execute(
            {
              name: 'applicationView',
              action: 'application/requestApplicationChanges',
              success: this.success,
            },
            {
              token: this.jwtToken,
              changes: this.constructPayload(),
              recaptcha_token: await this.recaptcha('personalInfoChangeRequestSubmit'),
            },
          );
        } finally {
          this.closeConfirmModal();
          this.workingOverall = false;
        }
      }
    },
    success() {
      this.$emit('formSubmitted');
    },
  },
};

function mapCardApplicant(source) {
  return {
    first_name: source.first_name,
    last_name: source.last_name,
    date_of_birth: source.date_of_birth,
    street: _get(source, 'address.street', null),
    city: _get(source, 'address.city', null),
    state: _get(source, 'address.state', null),
    zip: _get(source, 'address.zip', null),
  };
}
</script>

<style lang="scss" scoped></style>
