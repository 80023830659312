<template>
  <v-expansion-panel :key="stepNumber" :disabled="!enabled">
    <v-expansion-panel-header :hide-actions="true" class="application-stepper-header">
      <v-container class="fill-height">
        <v-row>
          <v-col style="flex-grow: 0">
            <v-avatar color="black" size="42" class="application-stepper-header-avatar">
              <span class="white--text">{{ stepNumber }}</span>
            </v-avatar>
          </v-col>
          <v-col class="application-stepper-heading-wrap">
            <h3 class="application-stepper-heading">{{ title }}</h3>
            <slot name="description"></slot>
          </v-col>
        </v-row>
      </v-container>
    </v-expansion-panel-header>
    <v-expansion-panel-content class="application-stepper-content">
      <v-container>
        <v-row>
          <v-col class="application-stepper-content-edit-column">
            <p v-if="!enabled && stepNumber !== 1" @click="editStep">{{ $t('application.step.edit') }}</p>
          </v-col>
          <v-col class="pt-0 pb-0">
            <slot name="content"></slot>
            <div class="application-stepper--actions">
              <button-success @click="submit" :loading="working" class="mt-3" data-test="submit-button" :text="buttonText" :is-disabled="submitBtnDisabled" />
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
export default {
  name: 'step',
  props: {
    title: {
      type: String,
      required: true,
    },
    subTitle: {
      type: String,
      default: 'text',
    },
    stepNumber: {
      type: Number,
      required: true,
    },
    enabled: {
      type: Boolean,
      default: true,
    },
    submitBtnDisabled: {
      type: Boolean,
    },
    buttonText: {
      type: String,
      required: true,
    },
    backButtonText: {
      type: String,
    },
  },
  methods: {
    editStep() {
      this.$emit('editTab');
    },
    submit() {
      this.$emit('submit');
    },
  },
};
</script>

<style lang="scss" scoped></style>
