<template>
  <div class="label-text">
    <h5>{{ label }}</h5>
    <slot>
      <p>{{ text }}</p>
    </slot>
  </div>
</template>

<script>
export default {
  name: 'label-text',
  props: {
    label: {
      type: String,
      required: true,
    },
    text: {
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
@import './../../../scss/utilities/custom-variables';
.theme--light.v-application {
  .label-text p {
    color: black;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
  }
}
</style>
