<template>
  <v-container fluid class="header">
    <v-row no-gutters class="header-row" align="start">
      <v-col class="header-logo-col">
        <a v-show="currentLocale === 'fr'" :href="$t('header.hello_link')" target="_blank"><img class="header-logo" src="@/assets/logo-fr.png" /></a>
        <a v-show="currentLocale === 'en'" :href="$t('header.hello_link')" target="_blank"><img class="header-logo" src="@/assets/logo-en.png" /></a>
        <a v-show="currentLocale !== 'en' && currentLocale !== 'fr'" :href="$t('header.hello_link')" target="_blank"><img class="header-logo" src="@/assets/logo-en.png" /></a>
      </v-col>
      <v-col :class="{ 'header-partner-logo-col': partnerLogo, 'no-partner-logo': !partnerLogo }">
        <a v-if="partnerLogo" :href="partnerUrl" target="_blank"><img class="partner-logo" :src="partnerLogo" /></a>
      </v-col>
      <v-spacer />
      <v-col align="right"> <language-switcher :locale="currentLocale" /> </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';

export default {
  name: 'header-main',
  data() {
    return {};
  },
  computed: {
    ...mapGetters('partner', ['partner']),
    ...mapGetters('ui', ['currentLocale']),
    partnerLogo() {
      return _isEmpty(this.partner) ? '' : _get(this.partner, `content.apply.header_logo_url.${this.currentLocale}`);
    },
    partnerUrl() {
      return _isEmpty(this.partner) ? '' : _get(this.partner, `content.program_url.${this.currentLocale}`);
    },
    partnerName() {
      return _isEmpty(this.partner) ? '' : _get(this.partner, `content.name.${this.currentLocale}`).toUpperCase();
    },
  },
};
</script>

<style lang="scss" scoped>
@import './../../scss/utilities/custom-variables';

.header {
  padding: 0;
  margin: 0;
  height: 64px;
  border-bottom: 30px solid white;
  background-color: $header-background-color;
  box-sizing: content-box;
}

.header-logo-col {
  background-color: white;
  text-align: right;
  height: $header-logo-height + $header-logo-padding-vertical * 2;
  flex-grow: 0;
  padding-left: 10%;
}

.header-logo {
  height: $header-logo-height;
  padding: $header-logo-padding-vertical $header-logo-padding-horizontal;
  box-sizing: content-box;
  background-color: white;
}

.header-partner-logo-col {
  border: 2px solid $header-background-color;
  border-top: none;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  background-color: white;
  flex-grow: 0;
}

.partner-logo {
  height: $header-logo-height * 2;
  padding: $header-logo-padding-vertical;
  display: block;
}

.no-partner-logo {
  border: none;
  background-color: $header-background-color;
}

@media screen and (max-width: 800px) {
  .header {
    border-bottom: 10px solid white;
  }
  .header-logo-col {
    padding-left: 0;
  }
  .header-logo {
    height: 22px;
    padding: $header-logo-padding-vertical 16px;
  }
  .partner-logo {
    height: 32px;
    padding: $header-logo-padding-vertical 17px;
  }
}
</style>
