<template>
  <v-footer class="footer" :padless="true">
    <v-container class="ctn-footer">
      <v-row>
        <v-col md="7" class="col">
          <v-row class="footer-menus">
            <v-col cols="12" sm="6" md="5" class="col">
              <h5>{{ $t('footer.legal') }}</h5>
              <template v-if="currentLocale === 'fr'">
                <a href="https://cdn.hellocard.ca/legal/pp-fr.html" target="_blank">{{ $t('footer.privacy_policy') }}</a> <a href="https://cdn.hellocard.ca/legal/tc-fr.html" target="_blank">{{ $t('footer.terms') }}</a>
                <a href="https://cdn.hellocard.ca/legal/cha-fr.html" target="_blank">{{ $t('footer.cardholder_agreement') }}</a>
              </template>
              <template v-else>
                <a href="https://cdn.hellocard.ca/legal/pp-en.html" target="_blank">{{ $t('footer.privacy_policy') }}</a> <a href="https://cdn.hellocard.ca/legal/tc-en.html" target="_blank">{{ $t('footer.terms') }}</a>
                <a href="https://cdn.hellocard.ca/legal/cha-en.html" target="_blank">{{ $t('footer.cardholder_agreement') }}</a>
              </template>
            </v-col>
            <v-col cols="12" sm="6" md="4" class="col">
              <h5>{{ $t('footer.info') }}</h5>
              <template v-if="currentLocale === 'fr'">
                <a href="https://www.hellocard.ca?lang=fr" target="_blank">{{ $t('footer.customer_service') }}</a> <a href="http://support.hellocard.ca/fr/" target="_blank">{{ $t('footer.faq') }}</a>
              </template>
              <template v-else>
                <a href="https://www.hellocard.ca?lang=en" target="_blank">{{ $t('footer.customer_service') }}</a> <a href="http://support.hellocard.ca/en/" target="_blank">{{ $t('footer.faq') }}</a>
              </template>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="5" class="col">
          <v-row>
            <v-col class="col">
              <p class="legal-mention" v-html="$t('footer.description')"></p>
              <p class="copyright">{{ $t('footer.copyright') }}</p>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'footer-main',
  data() {
    return {};
  },
  computed: {
    ...mapGetters('ui', ['currentLocale']),
  },
};
</script>

<style lang="scss" scoped>
@import './../../scss/utilities/custom-variables';

.footer > div {
}
</style>
