export default ({ Vue, app }) => {
  Vue.filter('formatPostalCode', (postalCode) => {
    if (postalCode) {
      const match = postalCode.match(/^(\w{3})(\w{3})$/);
      if (match && match.length >= 2) {
        return match[1] + ' ' + match[2];
      }
      return postalCode;
    } else {
      return app.i18n.t('system.na');
    }
  });
};
