import Vue from 'vue';
import Errors from './../utils/error/error-types';

const service = {
  async getPartner(id) {
    try {
      const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}/public/partner/v1/partners/${id}`);
      return response.data.data;
    } catch (e) {
      console.log(e); // eslint-disable-line no-console
      return null;
    }
  },
};

function isValidationError(e) {
  return e.response_code === 'validation.error';
}

function isResponseCardNotFound(r) {
  return r.data.result === 'fail' && r.data.error && r.data.error.code === 'system.resource.not.found' ? true : false;
}

function isRecaptchaError(e) {
  return e.response_code === 'recaptcha.failed';
}

function transformError(statusCode, code) {
  return new Errors.HttpError(statusCode, { error: { code } });
}

export default service;
