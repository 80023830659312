import '@/scss/main.scss';
// Fontawesome
import { dom, library } from '@fortawesome/fontawesome-svg-core';
import { faCheckCircle, faCopy, faFileImport, faUpload } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import VueCompositionAPI from '@vue/composition-api';
import Vue from 'vue';
import App from './App.vue';
import filters from './filters';
import pluginAxios from './plugins/axios';
import pluginI18n from './plugins/i18n';
import pluginRecaptchaV3 from './plugins/recaptcha-v3';
import pluginVeeValidate from './plugins/vee-validate';
import pluginVueClipboard from './plugins/vue-clipboard';
import pluginVuePageTitle from './plugins/vue-page-title';
import vuetify from './plugins/vuetify';
import { useFakeRecaptcha } from './utils/fake-plugin-helper';
import fakeRecaptcha from './plugins/fake-recaptcha';
import router from './router';
import './services';
import store from './store/store';

library.add(faCheckCircle, faCopy, faUpload, faFileImport);

Vue.component('font-awesome-icon', FontAwesomeIcon);

dom.watch();
// end Fontawesome

Vue.use(VueCompositionAPI);

Vue.config.productionTip = false;

const app = {
  router,
  store,
  vuetify,
  render: (h) => h(App),
};

const plugins = [];
plugins.push(pluginI18n);
plugins.push(pluginVuePageTitle);
plugins.push(pluginVeeValidate);
if (useFakeRecaptcha()) {
  plugins.push(fakeRecaptcha);
} else {
  plugins.push(pluginRecaptchaV3);
}
plugins.push(pluginAxios);
plugins.push(pluginVueClipboard);
plugins.push(filters);

plugins.forEach((plugin) =>
  plugin({
    app,
    store,
    router,
    Vue,
  }),
);

Vue.config.errorHandler = function (err, vm, info) {
  console.log('error catched in error handler', err); // eslint-disable-line no-console
  rg4js('send', {
    error: err,
    customData: [{ info }],
  });
};

new Vue(app).$mount('#app');
