<template>
  <div id="scroll-target">
    <header-main :partner-id="partnerId" />
    <v-main class="content">
      <v-container class="fill-height pb-8" v-bind="$attrs" v-on="$listeners">
        <v-row>
          <v-col><error context="global" /> <error context="render" /> </v-col>
        </v-row>
        <slot />
      </v-container>
    </v-main>
    <footer-main />
  </div>
</template>

<script>
export default {
  name: 'base-layout',
  props: {
    partnerId: {
      type: String,
      required: false,
    },
  },
};
</script>
