import Vue from 'vue';

export default {
  namespaced: true,
  state: {
    delete_documents_res: {},
  },

  getters: {
    delete_documents_res_original_count: (state) => state.delete_documents_res.original_count,
    delete_documents_res_deleted_count: (state) => state.delete_documents_res.deleted_count,
  },

  mutations: {
    delete_documents_res(state, delete_documents_res) {
      state.delete_documents_res = delete_documents_res;
    },
  },
  actions: {
    async deleteDocuments({ commit }, { token, recaptcha_token }) {
      const selfserve = Vue.prototype.$services.selfserve;

      const result = await selfserve.deleteDocuments({ token, recaptcha_token });
      commit('delete_documents_res', result);
    },
  },
};
