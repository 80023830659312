import Vue from 'vue';

export default {
  namespaced: true,

  state: {
    partner: {},
  },

  getters: {
    partner: (state) => state.partner,
  },

  mutations: {
    partner(state, partner) {
      state.partner = partner;
    },
  },

  actions: {
    async getPartner({ commit }, id) {
      const partnerService = Vue.prototype.$services.partner;
      const partner = await partnerService.getPartner(id);
      commit('partner', partner);
    },
  },
};
