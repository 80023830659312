import _get from 'lodash/get';
import Vue from 'vue';

export default {
  namespaced: true,
  state: {
    application: {
      address: {},
    },
    status: '',
    change_request_id: '',
    requested_changes: null,
  },

  getters: {
    application: (state) => state.application,
    status: (state) => state.status,
    change_request_id: (state) => state.change_request_id,
    requested_changes: (state) => state.requested_changes,
  },

  mutations: {
    application(state, application) {
      state.application = application;
    },
    status(state, status) {
      state.status = status;
    },
    change_request_id(state, change_request_id) {
      state.change_request_id = change_request_id;
    },
    requested_changes(state, requested_changes) {
      state.requested_changes = requested_changes;
    },
  },

  actions: {
    async getApplicationValues({ dispatch, commit }, { token }) {
      const applicationService = Vue.prototype.$services.application;
      const result = await applicationService.getApplicationValues({ token });
      commit('application', _get(result, 'card_application.card_applicant', {}));
      commit('status', result.status);
      commit('change_request_id', result.id);
      commit('requested_changes', result.requested_changes);

      rg4js('setUser', {
        identifier: result.id,
        isAnonymous: false,
      });

      if (result.partner_id) {
        dispatch('partner/getPartner', result.partner_id, { root: true });
      }

      return result;
    },
    async requestApplicationChanges({ commit, dispatch }, { token, changes, recaptcha_token }) {
      const applicationService = Vue.prototype.$services.application;
      const result = await applicationService.requestApplicationChanges({ token, changes, recaptcha_token });

      commit('change_request_id', result.id);
    },
  },
};
