<template>
  <v-text-field v-bind="$attrs" :label="label" :type="type" v-mask="{ mask: theMask, unmaskedVar: 'unmaskedValue' }" v-model="myValue" v-on="listeners"></v-text-field>
</template>

<script>
import { mask } from '@titou10/v-mask';

export default {
  name: 'hello-masked-text-field',
  directives: {
    mask,
  },
  props: {
    value: {
      required: true,
    },
    label: {
      type: String,
      required: false,
    },
    theMask: {
      required: true,
    },
    type: {
      type: String,
      default: 'text',
    },
  },
  data() {
    return {
      unmaskedValue: '',
      myValue: this.value,
    };
  },
  watch: {
    value() {
      this.myValue = this.value;
    },
    unmaskedValue() {
      if (this.myValue || this.value) {
        this.$emit('input', this.unmaskedValue);
      }
    },
  },
  computed: {
    listeners() {
      const { input, ...listeners } = this.$listeners;
      return listeners;
    },
  },
};
</script>
