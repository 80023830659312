<template>
  <v-btn text :loading="loading" :disabled="isDisabled" v-on="listeners" v-bind="$attrs" rounded :class="{ 'secondary-button': true }">{{ text }}</v-btn>
</template>

<script>
export default {
  name: 'button-back',
  props: {
    text: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        button: (event) => this.$emit('button', event.target.value),
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import './../../scss/main.scss';
.secondary-button.v-btn {
  color: $primary;
  font-weight: bold;
  padding-left: 0;
  &:hover,
  &:focus {
    &:before {
      opacity: 0;
    }
  }
}
</style>
