import * as VeeValidate from 'vee-validate/dist/vee-validate.full';
import en from '../i18n/vee-validate/en.json';
import fr from '../i18n/vee-validate/fr.json';
import BaseJoi from 'joi';
import zip from './../utils/validators/zip';
import moment from 'moment';

export default ({ Vue, app }) => {
  VeeValidate.localize({ fr, en });
  VeeValidate.localize('en');

  Vue.component('ValidationProvider', VeeValidate.ValidationProvider);
  Vue.component('ValidationObserver', VeeValidate.ValidationObserver);

  const Joi = BaseJoi.extend(zip);

  VeeValidate.extend('zip', {
    name: 'zip',
    message(field, args) {
      return app.i18n.t('validation.joi.string.zip');
    },
    validate: (value) => {
      const schema = Joi.string().zip();
      return Joi.validate(value, schema)
        .then((resp) => {
          return true;
        })
        .catch((e) => {
          if (e.isJoi && e.name === 'ValidationError') {
            return false;
          }

          throw e;
        });
    },
  });

  VeeValidate.extend('length', {
    name: 'length',
    message(field, args) {
      if (field === 'zip') {
        return app.i18n.t('validation.custom.string.length.zip');
      } else {
        return app.i18n.t('validation.custom.string.length.default', { length: args.length });
      }
    },
  });

  VeeValidate.extend('before', {
    name: 'before',
    message(field, args) {
      return app.i18n.t('validation.custom.date.before');
    },
    computesRequired: true,
    validate: (value, params) => {
      try {
        return moment(value, 'YYYY-MM-DD').isBefore(moment(params[0], 'YYYY-MM-DD'));
      } catch (err) {
        return false;
      }
    },
  });

  VeeValidate.extend('date_format', {
    name: 'date_format',
    message(field, args) {
      return app.i18n.t('validation.custom.date.date_format');
    },
    computesRequired: true,
    validate: (value, params) => {
      try {
        return moment(value, 'YYYY-MM-DD').isValid();
      } catch (err) {
        return false;
      }
    },
  });

  // Note: provide size in bytes
  VeeValidate.extend('file_size', {
    name: 'file_size',
    message(field, args) {
      const sizeInMegabytes = args[0] / 1000000;
      return app.i18n.t('validation.custom.file.file_size', { size: sizeInMegabytes });
    },
    validate: (value, params) => {
      try {
        return value.size < params[0];
      } catch (err) {
        return false;
      }
    },
  });

  Vue.use(VeeValidate);
};
