export default {
  async created() {
    await this.$recaptchaLoaded();
    const recaptcha = this.$recaptchaInstance;
    recaptcha.hideBadge();
  },
  methods: {
    async recaptcha(action) {
      await this.$recaptchaLoaded();
      return await this.$recaptcha(action);
    },
  },
};
