<template>
  <base-layout>
    <v-row>
      <v-col offset="1" cols="10">
        <v-alert type="info"> {{ $t('pageNotFound.message') }} </v-alert>
      </v-col>
    </v-row>
  </base-layout>
</template>

<script>
import { renderErrorTrackable } from '@/mixins';
import { mapGetters } from 'vuex';
import safeExecute from '../../composables/safe-execute';

export default {
  setup(props, { root }) {
    const { execute, working } = safeExecute(root.$store);

    return {
      execute,
      working,
    };
  },
  name: 'page-not-found-view',
  title: (args) => args.$t('page_title.page_not_found'),
  mixins: [renderErrorTrackable],
  watch: {
    currentLocale() {
      this.$title = this.$t('page_title.page_not_found');
    },
  },
  computed: {
    ...mapGetters('ui', ['currentLocale']),
  },
  methods: {
    async test() {
      await this.execute(
        {
          action: 'general/test',
          name: 'generalTest',
        },
        { partner: 'pid' },
      );
    },
  },
};
</script>
