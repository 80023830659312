<template>
  <v-alert class="error-banner" v-model="show" type="error" transition="scale-transition" outlined icon="fa-exclamation-square">
    <div v-if="title" class="title">{{ title }}</div>
    <div>{{ text }}</div>
  </v-alert>
</template>

<script>
export default {
  name: 'warning-banner',
  props: {
    title: {
      type: String,
    },
    text: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      show: true,
    };
  },
};
</script>

<style scoped>
div {
  text-align: center;
}

div.title {
  font-weight: 900;
  text-transform: uppercase;
}
</style>
