<template>
  <v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y :min-width="minWidth">
    <template v-slot:activator="{ on }">
      <v-text-field data-test="date-picker" :disabled="disabled" v-model="myValue" :label="label" :error="error" :error-messages="errorMessages" counter :maxlength="maxlength" readonly v-on="on"></v-text-field>
    </template>
    <v-date-picker ref="picker" v-model="myValue" color="black" :max="maxDate" :min="minDate" v-bind="$attrs" v-on="$listeners" @change="save"></v-date-picker>
  </v-menu>
</template>

<script>
export default {
  inheritAttrs: false,
  name: 'hello-date-picker',
  props: {
    value: {
      required: true,
      default: null,
    },
    label: {
      type: String,
      required: true,
    },
    error: {
      required: false,
    },
    errorMessages: {
      required: false,
    },
    maxlength: {
      type: Number,
      required: true,
    },
    minWidth: {
      type: String,
      required: true,
    },
    minDate: {
      type: String,
      required: false,
    },
    maxDate: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      menu: false,
      myValue: this.value,
    };
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'));
      if (!val) {
        this.$emit('input', this.myValue);
      }
    },
    value() {
      this.myValue = this.value;
    },
    myValue(val) {
      this.$emit('input', val);
    },
  },
  methods: {
    save(val) {
      this.$emit('input', val);
      this.$refs.menu.save(val);
    },
  },
};
</script>
