<template>
  <v-dialog v-if="avoidRendering ? showDialog : true" v-model="showDialog" persistent :max-width="maxWidth" :hide-overlay="hideOverlay">
    <slot></slot>
  </v-dialog>
</template>

<script>
export default {
  name: 'modal-action',
  props: {
    showModal: {
      type: Boolean,
      required: true,
      default: false,
    },
    avoidRendering: {
      type: Boolean,
      default: false,
    },
    hideOverlay: {
      type: Boolean,
      default: false,
    },
    maxWidth: {
      type: Number,
      default: 700,
    },
  },
  data() {
    return {
      // Important for the reactivity if clicking out of the dialog box closes the modal
      showDialog: this.showModal,
    };
  },
  watch: {
    showModal: function (newVal) {
      // Important for the reactivity if clicking out of the dialog box closes the modal
      this.showDialog = newVal;
    },
    showDialog: function (newVal) {
      // Important for the reactivity if clicking out of the dialog box closes the modal
      if (newVal === false) {
        this.$emit('close');
      }
    },
  },
};
</script>
