<template>
  <validation-provider ref="validator" :rules="rules" v-slot="{ valid, untouched, errors }" :name="name"> <slot :validationState="valid || untouched" :errors="errors"></slot> </validation-provider>
</template>

<script>
export default {
  name: 'hello-validation-provider',
  props: {
    rules: {
      type: [Object, String],
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
  },
};
</script>
