import _get from 'lodash/get';
import { transformGeneralError } from '@/utils/error/error-transformer';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async test({}, params) {
      console.log(params); // eslint-disable-line no-console
    },
    async execute({ dispatch }, { context, params }) {
      let res;
      try {
        let actionResult = await dispatch(context.action, params, { root: true });

        // Post success hook
        if (context.success) {
          res = (await context.success(actionResult)) || actionResult;
        }
      } catch (err) {
        let error;

        //Convert Store Error
        if (!err.errorType) {
          error = transformGeneralError(err, 'store');
        } else {
          error = err;
        }

        // Pre Error Add Hook
        if (context.beforeError) {
          res = (await context.beforeError(error)) || error;
        }

        await dispatch('ui/addError', { contextName: _get(context, 'name', 'global'), error: error }, { root: true });

        // After Error Add Hook
        if (context.afterError) {
          res = (await context.afterError(error)) || error;
        }
      } finally {
        // Finally Hook
        if (context.finally) {
          res = (await context.finally()) || res;
        }
      }

      return res;
    },
  },
};
