<template>
  <v-btn text depressed :ripple="false" v-on="listeners" v-bind="$attrs" class="button-modal-cancel">{{ text }}</v-btn>
</template>

<script>
export default {
  name: 'button-modal-cancel',
  props: {
    text: {
      type: String,
      required: true,
    },
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        button: (event) => this.$emit('button', event.target.value),
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import './../../scss/utilities/custom-variables';

.button-modal-cancel.v-btn {
  text-transform: uppercase;
  font-weight: bold;
  color: $primary;
  padding-left: 0;
  &:hover,
  &:focus {
    opacity: $text-hover-opacity;
    &:before {
      opacity: 0;
    }
  }
}
</style>
