<template>
  <div>
    <alert-system-error :error="currentError" :text="getErrorTranslation()" v-if="currentError && currentError.errorType === 'system' && show"></alert-system-error>

    <alert-normal-error :text="getErrorTranslation()" v-else-if="currentError && currentError.errorType === 'http' && getErrorTranslation() !== currentError.code"></alert-normal-error>

    <alert-system-error :error="currentError" :text="getErrorTranslation()" v-if="currentError && currentError.errorType === 'http' && getErrorTranslation() === currentError.code"></alert-system-error>

    <alert-app-error :stack="currentError.stack" :text="getErrorTranslation()" v-else-if="currentError && currentError.errorType === 'app' && show"></alert-app-error>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import _reduce from 'lodash/reduce';
import _isEmpty from 'lodash/isEmpty';
import _isUndefined from 'lodash/isUndefined';

export default {
  name: 'error',
  props: {
    context: {
      type: String,
    },
  },
  computed: {
    ...mapGetters('ui', ['error']),
    displayErrorStackUserFriendly() {
      return `<v-layout column>${_reduce(
        this.currentError.stack.split('\n'),
        (acc, e) => {
          acc += `<div>${e}</div>`;
          return acc;
        },
        '',
      )}</v-layout>`;
    },
  },
  watch: {
    error: function () {
      this.currentError = this.error[this.context];
      this.show = !_isEmpty(this.currentError) && !_isUndefined(this.currentError);
      if (!this.currentError) {
        this.showErrorStack = false;
      }
    },
  },
  data() {
    return {
      show: false,
      showErrorStack: false,
      currentError: {},
    };
  },
  methods: {
    showStack() {
      this.showErrorStack = !this.showErrorStack;
    },
    getErrorTranslation() {
      return this.$t(this.currentError.code, { message: this.currentError.msg });
    },
  },
};
</script>
