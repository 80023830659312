<template>
  <v-row class="error-view" data-test="error-view">
    <v-col class="pt-0">
      <v-alert class="view-alert" v-model="show" type="error" outlined max-width="841" transition="scale-transition" :icon="false">
        <h2 class="title">{{ $t(`error_view.title.${title}`) }}</h2>
        <p v-if="text" class="text">{{ $t(`error_view.text.${text}`) }}</p>
        <p v-if="support" class="support mb-0" v-html="$t(`error_view.support.${support}`)" />
      </v-alert>
    </v-col>
  </v-row>
</template>

<script>
import { renderErrorTrackable } from '@/mixins';

export default {
  name: 'error-view',
  mixins: [renderErrorTrackable],
  props: {
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: false,
      default: 'default',
    },
    support: {
      type: String,
      required: false,
      default: 'default',
    },
  },
  data() {
    return {
      show: true,
    };
  },
};
</script>

<style lang="scss" scoped>
@import './../../scss/utilities/custom-variables.scss';
.theme--light.v-application {
  .error-view {
    .view-alert {
      border-radius: $border-radius;
      margin: 5% auto 10%;
      padding: $card-padding;
    }
  }

  h2.title {
    text-align: center;
    font-weight: 900;
    text-transform: uppercase;
    padding-bottom: 1.3em;
    color: $error-color;
  }

  .text {
    margin-bottom: 1.1em;
    color: $error-color;
  }

  .support {
    font-size: 14px;
    color: $error-color;
  }
}
</style>
