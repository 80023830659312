var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0"},[_c('validation-observer',{ref:"observer",attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit($event)}}},[_c('v-container',{staticClass:"pr-0 pl-0"},[_c('v-row',[_c('v-col',{staticClass:"col",attrs:{"cols":"12","sm":"4","md":"3"}},[_c('hello-validation-provider',{ref:"validator",attrs:{"rules":"required|max:20","name":_vm.$t('personal_info_change.fields.first_name')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validationState = ref.validationState;
var errors = ref.errors;
return [_c('v-text-field',{ref:"first_field",attrs:{"disabled":!_vm.requested_changes.first_name_verification_required,"type":"text","autocomplete":"new-first_name","counter":"","error":!validationState,"error-messages":errors,"maxlength":"20","label":_vm.$t('personal_info_change.fields.first_name') + ' *',"data-test":"personal_info_change--first-name"},model:{value:(_vm.currentCardApplicant.first_name),callback:function ($$v) {_vm.$set(_vm.currentCardApplicant, "first_name", $$v)},expression:"currentCardApplicant.first_name"}})]}}],null,true)})],1),_c('v-col',{staticClass:"col",attrs:{"cols":"12","sm":"4","md":"3"}},[_c('hello-validation-provider',{attrs:{"rules":"required|max:20","name":_vm.$t('personal_info_change.fields.last_name')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validationState = ref.validationState;
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"disabled":!_vm.requested_changes.last_name_verification_required,"autocomplete":"new-last_name","counter":"","error":!validationState,"error-messages":errors,"maxlength":"20","label":_vm.$t('personal_info_change.fields.last_name') + ' *',"data-test":"personal_info_change--last-name"},model:{value:(_vm.currentCardApplicant.last_name),callback:function ($$v) {_vm.$set(_vm.currentCardApplicant, "last_name", $$v)},expression:"currentCardApplicant.last_name"}})]}}],null,true)})],1),(_vm.requested_changes.dob_verification_required)?_c('v-col',{staticClass:"col",attrs:{"cols":"12","sm":"4","md":"3"}},[_c('hello-validation-provider',{attrs:{"rules":"required|max:10","name":_vm.$t('personal_info_change.fields.birthdate')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validationState = ref.validationState;
var errors = ref.errors;
return [_c('hello-date-picker',{attrs:{"error":!validationState,"error-messages":errors,"locale":_vm.currentLocale,"label":_vm.$t("personal_info_change.fields.birthdate") + ' *',"max-date":_vm.maxBirthdate(),"min-date":_vm.minBirthdate(),"maxlength":10,"min-width":"290px"},model:{value:(_vm.currentCardApplicant.date_of_birth),callback:function ($$v) {_vm.$set(_vm.currentCardApplicant, "date_of_birth", $$v)},expression:"currentCardApplicant.date_of_birth"}})]}}],null,true)})],1):_vm._e()],1),(_vm.requested_changes.address_verification_required)?_c('h4',[_vm._v(_vm._s(_vm.$t('personal_info_change.fields.address')))]):_vm._e(),_c('v-row',[(_vm.requested_changes.address_verification_required)?_c('v-col',{staticClass:"col",attrs:{"cols":"12","sm":"9"}},[_c('hello-validation-provider',{attrs:{"rules":"required|max:50","name":_vm.$t('personal_info_change.fields.street')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validationState = ref.validationState;
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"text","autocomplete":"new-street","counter":"","error":!validationState,"error-messages":errors,"maxlength":"50","label":_vm.$t('personal_info_change.fields.street') + ' *',"data-test":"personal_info_change--street"},model:{value:(_vm.currentCardApplicant.street),callback:function ($$v) {_vm.$set(_vm.currentCardApplicant, "street", $$v)},expression:"currentCardApplicant.street"}})]}}],null,true)})],1):_vm._e()],1),_c('v-row',[(_vm.requested_changes.address_verification_required)?_c('v-col',{staticClass:"col",attrs:{"cols":"12","sm":"4","md":"3"}},[_c('hello-validation-provider',{attrs:{"rules":"required|max:50","name":_vm.$t('personal_info_change.fields.city')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validationState = ref.validationState;
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"text","autocomplete":"new-city","counter":"","error":!validationState,"error-messages":errors,"maxlength":"50","label":_vm.$t('personal_info_change.fields.city') + ' *',"data-test":"personal_info_change--city"},model:{value:(_vm.currentCardApplicant.city),callback:function ($$v) {_vm.$set(_vm.currentCardApplicant, "city", $$v)},expression:"currentCardApplicant.city"}})]}}],null,true)})],1):_vm._e(),(_vm.requested_changes.address_verification_required)?_c('v-col',{staticClass:"col",attrs:{"cols":"12","sm":"4","md":"3"}},[_c('hello-validation-provider',{attrs:{"rules":"required|max:2","name":_vm.$t('personal_info_change.fields.state')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validationState = ref.validationState;
var errors = ref.errors;
return [_c('v-select',{attrs:{"autocomplete":"new-state","error":!validationState,"error-messages":errors,"label":_vm.$t("personal_info_change.fields.state") + ' *',"items":_vm.stateOptions,"item-text":"name","item-value":"abbr","data-test":"personal_info_change--state"},model:{value:(_vm.currentCardApplicant.state),callback:function ($$v) {_vm.$set(_vm.currentCardApplicant, "state", $$v)},expression:"currentCardApplicant.state"}})]}}],null,true)})],1):_vm._e(),(_vm.requested_changes.address_verification_required)?_c('v-col',{staticClass:"col",attrs:{"cols":"12","sm":"4","md":"3"}},[_c('hello-validation-provider',{attrs:{"rules":"required|length:6|zip","name":"zip"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validationState = ref.validationState;
var errors = ref.errors;
return [_c('hello-masked-text-field',{attrs:{"type":"text","autocomplete":"new-zip","error":!validationState,"error-messages":errors,"hint":"X9X 9X9","label":_vm.$t('personal_info_change.fields.zip') + ' *',"the-mask":"A#A #A#","data-test":"personal_info_change--postal-code"},model:{value:(_vm.currentCardApplicant.zip),callback:function ($$v) {_vm.$set(_vm.currentCardApplicant, "zip", $$v)},expression:"currentCardApplicant.zip"}})]}}],null,true)})],1):_vm._e()],1),(_vm.documentsRequested)?_c('v-row',{staticClass:"pt-6",attrs:{"dense":""}},[_c('v-col',[_c('h4',[_vm._v(_vm._s(_vm.$t('personal_info_change.documents_section.title')))])])],1):_vm._e(),(_vm.documentRequested)?_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('hello-validation-provider',{attrs:{"rules":'file_size:' + _vm.maxFileSizeInBytes,"name":"file_size"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validationState = ref.validationState;
var errors = ref.errors;
return [_c('v-file-input',{attrs:{"error":!validationState,"error-messages":errors,"accept":".pdf, .jpg, .jpeg, .png","truncate-length":"100","label":_vm.$t("personal_info_change.documents_section.upload_document"),"show-size":"","dense":""},model:{value:(_vm.document1),callback:function ($$v) {_vm.document1=$$v},expression:"document1"}})]}}],null,true)})],1)],1):_vm._e(),(_vm.documentsRequested)?_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('hello-validation-provider',{attrs:{"rules":'file_size:' + _vm.maxFileSizeInBytes,"name":"file_size"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validationState = ref.validationState;
var errors = ref.errors;
return [_c('v-file-input',{attrs:{"error":!validationState,"error-messages":errors,"accept":".pdf, .jpg, .jpeg, .png","truncate-length":"100","label":_vm.$t("personal_info_change.documents_section.upload_document"),"show-size":"","dense":""},model:{value:(_vm.document2),callback:function ($$v) {_vm.document2=$$v},expression:"document2"}})]}}],null,true)})],1)],1):_vm._e(),_c('button-success',{staticClass:"mt-5",attrs:{"loading":_vm.workingOverall,"is-disabled":!valid,"text":_vm.$t('personal_info_change.submit_button'),"data-test":"personal_info_change--button-success"},on:{"click":_vm.confirm}})],1)],1)]}}])}),_c('modal-confirm-personal-info-changes',{attrs:{"show-modal":_vm.showConfirmModal,"title":_vm.$t(("personal_info_change.confirm." + _vm.confirmModalCase + ".title")),"context":"PersonalInfoChangeRequest","paragraph1":_vm.$t(("personal_info_change.confirm." + _vm.confirmModalCase + ".paragraph1")),"paragraph2":_vm.$t(("personal_info_change.confirm." + _vm.confirmModalCase + ".paragraph2")),"url":_vm.$t(("personal_info_change.confirm." + _vm.confirmModalCase + ".url")),"cancel_text":_vm.$t(("personal_info_change.confirm." + _vm.confirmModalCase + ".button_cancel")),"submit_text":_vm.$t(("personal_info_change.confirm." + _vm.confirmModalCase + ".button_submit")),"submit_working":_vm.working},on:{"submit":_vm.submit,"close":_vm.closeConfirmModal}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }