import PageNotFoundView from '@/components/views/PageNotFoundView.vue';
import PersonalInfoChangeRequest from '@/components/views/PersonalInfoChangeRequest';
import Vue from 'vue';
import Router from 'vue-router';
import { registerSystemComponent } from './components/system';
const { isNavigationFailure, NavigationFailureType } = Router;

Vue.use(Router);

registerSystemComponent(Vue);

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/not-found',
      name: 'not-found',
      component: PageNotFoundView,
    },
    {
      path: '/',
      name: 'home',
      component: PageNotFoundView,
    },
    {
      path: '/application/:jwtToken',
      name: 'personal-info-change-request',
      component: PersonalInfoChangeRequest,
      props: true,
    },
    {
      path: '*',
      component: PageNotFoundView,
    },
  ],
});

router.afterEach((to, from) => {
  rg4js('trackEvent', {
    type: 'pageView',
    path: to.path,
  });
});

router.pushNoDuplicate = (params) => {
  router.push(params, undefined, (failure) => {
    if (isNavigationFailure(failure, NavigationFailureType.redirected)) {
      // When in navigation guard there is a redirect. ex: next(newLocation)
    } else if (isNavigationFailure(failure, NavigationFailureType.aborted)) {
      // When in the navigation guard the navigation is aborted ex: next(false)
    } else if (isNavigationFailure(failure, NavigationFailureType.cancelled)) {
      // When another navigation occurs before the end of the navigation in progress. Ex: load the space home and spam my account before the render is completed
    } else if (isNavigationFailure(failure, NavigationFailureType.duplicated)) {
      // When another navigation target the same destination.  EX: my account to my account
    } else {
      throw failure;
    }
  });
};

export default router;
