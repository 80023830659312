import Vue from 'vue';

export default {
  namespaced: true,
  state: {
    cardholderAgreements: {},
  },

  getters: {
    cardholderAgreements: (state) => (locale) => state.cardholderAgreements[locale],
  },

  mutations: {
    cardholderAgreements(state, cardholderAgreements) {
      state.cardholderAgreements = cardholderAgreements;
    },
  },

  actions: {
    async loadCardholderAgreements({ commit }) {
      const legalService = Vue.prototype.$services.legal;
      const cardholderAgreementEn = await legalService.loadCardholderAgreement('en');
      const cardholderAgreementFr = await legalService.loadCardholderAgreement('fr');
      commit('cardholderAgreements', {
        en: cardholderAgreementEn,
        fr: cardholderAgreementFr,
      });
    },
  },
};
