module.exports = {
  error_view: {
    support: {
      default: 'Si vous avez besoin d\'assistance, suivre ce lien: <a target="_blank" href="https://www.hellocard.ca/support">https://www.hellocard.ca/support</a>.',
    },
    text: {
      default: 'Désolé, une erreur inconnue est survenue.',
      jwt_expired: 'Désolé, le lien a expiré.',
      jwt_invalid: 'Désolé, le lien est invalide.',
    },
    title: {
      personal_info_change: 'DEMANDE DE CHANGEMENT DE VOTRE INFORMATION PERSONNELLE',
    },
  },
  errors: {
    http: {
      'invalid_change-request_state': 'Vous avez déjà soumis vos informations. Veuillez recharger cette page svp.',
      jwt_verify_failed: 'Verification du jeton JWT a été refusé',
      personal_info_change_recaptcha_failed: 'Le délai pour le reCAPTCHA a expiré. Veuillez soumettre de nouveau.',
    },
    system: {
      system_bad_gateway: "Problème d'API inconnu",
      system_forbidden: 'Accès interdit: {message}',
      system_resource_not_found: 'Information non existante!',
      system_unauthorized: 'Non autorisé: {message}',
      validation_error: 'Erreur de validation: {message}',
    },
    systemError: 'ERREUR SYSTÈME: ',
  },
  footer: {
    app: 'App',
    cardholder_agreement: 'Entente du titulaire de carte',
    company: 'Company',
    copyright: '© 2020 HELLO. TOUS DROITS RÉSERVÉS.',
    customer_service: 'Service à la clientèle',
    description:
      'La <b>carte prépayée Hello Visa*</b> est émise par la Compagnie de Fiducie Peoples, en vertu d’une licence de Visa Int. *Marque de commerce de Visa International Service Association et utilisée sous licence de la Compagnie de Fiducie Peoples.',
    faq: 'FAQ',
    hello_card: 'Carte Hello',
    how_it_works: 'Fonctionnement',
    info: 'Assistance',
    legal: 'Mentions juridiques',
    privacy_policy: 'Politique de vie privée',
    terms: "Conditions d'utilisation",
  },
  header: {
    hello_link: 'https://hellocard.ca',
  },
  pageNotFound: {
    message: "Désolé, cette page n'existe pas.",
  },
  page_title: {
    error_view: 'Erreur',
    page_not_found: 'Page introuvable',
    personal_info_change_request_view: 'Demande de changement',
  },
  pending_view: {
    change_request_id: 'Identifiant de demande',
    description: "Veuillez attendre l'approbation ou le rejet de la dernière demande de changement avant de pouvoir soumettre une nouvelle demande.",
    questions: 'Si vous avez des questions en lien avec la carte, contactez notre service à la clientèle au 1-833-654-3556.',
    title: 'Vous avez une demande de changement <span>en attente</span>.',
  },
  personal_info_change: {
    confirm: {
      changes_required: {
        button_cancel: 'NON, JE VEUX METTRE À JOUR MON INFORMATION',
        button_submit: 'OUI, SOUMETTRE TEL QUEL',
        paragraph1:
          'On vous a demandé de vérifier votre information et apporter des changements si nécessaire. Si vous soumettez le formulaire tel quel, vous allez recevoir un autre courriel avec les instructions pour télécharger un ou deux documents pour compléter votre processus de vérification d’identité.',
        paragraph2: 'Pour mieux comprendre le processus de vérification d’identité, visitez ',
        title: 'ÊTES-VOUS CERTAIN?',
        url: 'www.hellocard.ca/verification?lang=fr',
      },
      loading: {
        button_cancel: '',
        button_submit: '',
        paragraph1: '',
        paragraph2: '',
        title: '',
        url: '',
      },
      one_doc: {
        button_cancel: 'NON, je vais télécharger mon document',
        button_submit: 'OUI, SOUMETTRE TEL QUEL',
        paragraph1:
          'On vous a demandé de télécharger un document pour compléter votre processus de vérification d’identité. Si vous soumettez le formulaire tel quel, nous ne serons pas en mesure de compléter votre processus de vérification d’identité et nous ne pourrons pas vous émettre une carte Hello.',
        paragraph2: 'Pour mieux comprendre le processus de vérification d’identité et consulter la liste des documents que nous pouvons accepter, visitez ',
        title: 'ÊTES-VOUS CERTAIN?',
        url: 'www.hellocard.ca/verification?lang=fr',
      },
      two_docs: {
        button_cancel: 'NON, je vais télécharger mes documentS',
        button_submit: 'OUI, SOUMETTRE TEL QUEL',
        paragraph1:
          'On vous a demandé de télécharger deux documents pour compléter votre processus de vérification d’identité. Si vous soumettez le formulaire tel quel, nous ne serons pas en mesure de compléter votre processus de vérification d’identité et nous ne pourrons pas vous émettre une carte Hello.',
        paragraph2: 'Pour mieux comprendre le processus de vérification d’identité et consulter la liste des documents que nous pouvons accepter, visitez ',
        title: 'ÊTES-VOUS CERTAIN?',
        url: 'www.hellocard.ca/verification?lang=fr',
      },
    },
    description: 'Complétez le formulaire ci-dessous et cliquez Soumettre pour mettre à jour votre information personnelle.',
    documents_section: {
      title: 'Documents',
      upload_document: 'Télécharger le document PDF, PNG, JPG ou JPEG',
    },
    fields: {
      address: 'Adresse de résidence principale',
      birthdate: 'Date de naissance',
      city: 'Ville',
      first_name: 'Prénom',
      last_name: 'Nom de famille',
      state: 'Province',
      street: 'No civique et nom de rue',
      zip: 'Code postal',
    },
    heading: 'DEMANDE DE CHANGEMENT DE VOTRE INFORMATION PERSONNELLE',
    submit_button: 'Soumettre',
  },
  processed_view: {
    change_request_id: 'Identifiant de la demande',
    description: 'Une nouvelle demande de changement est requis pour mettre à jour votre information personnelle.',
    questions: 'Si vous avez des questions en lien avec la carte, contactez notre service à la clientèle au 1-833-654-3556.',
    title: 'Votre demande de changement a été traitée.',
  },
  state: {
    ab: 'Alberta',
    bc: 'Colombie-Britannique',
    mb: 'Manitoba',
    nb: 'Nouveau-Brunswick',
    nl: 'Terre-Neuve',
    ns: 'Nouvelle-Écosse',
    nt: 'Territoires du Nord-Ouest',
    nu: 'Nunavut',
    on: 'Ontario',
    pe: 'Île-du-Prince-Édouard',
    qc: 'Québec',
    sk: 'Saskatchewan',
    yt: 'Yukon',
  },
  success_view: {
    change_request_id: 'Identifiant de la demande',
    description: "Nous avons reçu votre demande de changement. Nous vous recommandons de garder en note l'identifiant de la demande pour référence future.",
    questions: 'Si vous avez des questions en lien avec la carte, contactez notre service à la clientèle au 1-833-654-3556.',
    title: 'Votre demande de changement a été transmise avec <span>succès</span>!',
  },
  validation: {
    custom: {
      date: {
        before: "L'age minimum est de 18 ans",
        date_format: 'Format invalide (YYYY-MM-DD)',
      },
      file: {
        file_size: 'La taille du fichier ne doit pas dépasser {size} MB',
      },
      string: {
        length: {
          default: 'Doit avoir {length} caractères',
          zip: 'Format invalide (XXX XXX)',
        },
      },
    },
  },
};
