<template>
  <v-dialog v-if="avoidRendering ? showDialog : true" v-model="showDialog" scrollable max-width="1024px">
    <v-card class="basic-modal-styles modal-simple">
      <v-card-title v-if="title" class="modal-title">
        <h3>{{ title }}</h3>
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text>
        <slot name="modalContent"> {{ text }} </slot>
      </v-card-text>

      <v-card-actions class="modal-actions">
        <button-modal-cancel :text="closeText ? closeText : scrollableModal.defaultCloseText" @click="close" class="modal-cancel-button"></button-modal-cancel>
        <button-success v-if="actionText" @click="doAction" :text="actionText" class="modal-submit-button" data-test="modal--submit-button"></button-success>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'modal',
  props: {
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: false,
    },
    closeText: {
      type: String,
      required: false,
    },
    actionText: {
      type: String,
      required: false,
    },
    showModal: {
      type: Boolean,
      required: true,
      default: false,
    },
    avoidRendering: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // Important for the reactivity if clicking out of the dialog box closes the modal
      showDialog: this.showModal,
    };
  },
  watch: {
    showModal: function (newVal) {
      // Important for the reactivity if clicking out of the dialog box closes the modal
      this.showDialog = newVal;
    },
    showDialog: function (newVal) {
      // Important for the reactivity if clicking out of the dialog box closes the modal
      if (newVal === false) {
        this.$emit('close');
      }
    },
  },
  methods: {
    doAction() {
      this.$emit('action');
      this.close();
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>
