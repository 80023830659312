<template>
  <v-btn :loading="loading" :disabled="isDisabled" primary dark rounded depressed height="40" color="primary" v-on="listeners" v-bind="$attrs" :class="{ 'primary-button': true }">{{ text }}</v-btn>
</template>

<script>
export default {
  inheritAttrs: false,
  name: 'button-success',
  props: {
    text: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        button: (event) => this.$emit('button', event.target.value),
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import './../../scss/utilities/custom-variables';

.primary-button.v-btn {
  text-transform: uppercase;
  font-weight: bold;
  min-width: 180px !important;
  padding-right: 30px;
  padding-left: 30px;
  background-color: $body-action-color !important;
  &:hover,
  &:focus {
    &:before {
      opacity: 0.2;
    }
  }
}
</style>
