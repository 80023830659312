'use strict';

export default (Joi) => {
  return {
    base: Joi.string(),
    name: 'string',
    language: {
      zip: 'not a valid zip for "CA" or does not respect the pattern "X9X 9X9"',
    },
    rules: [
      {
        name: 'zip',
        validate(params, value, state, options) {
          const valueToCheck = value.trim().toUpperCase();

          if (!/^[ABCEGHJ-NPRSTVXY][0-9][ABCEGHJ-NPRSTV-Z][0-9][ABCEGHJ-NPRSTV-Z][0-9]$/.test(valueToCheck)) {
            return this.createError('string.zip', { v: value }, state, options);
          }

          return value.toUpperCase();
        },
      },
    ],
  };
};
