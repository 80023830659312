import { VueReCaptcha } from 'vue-recaptcha-v3';

export default ({ Vue }) => {
  Vue.use(VueReCaptcha, {
    siteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
    loaderOptions: {
      useRecaptchaNet: true,
    },
  });
};
