import _pick from 'lodash/pick';
import _isEqual from 'lodash/isEqual';
import _compact from 'lodash/compact';

function address_fields_changed(original, new_values) {
  if (!_isEqual(_pick(original, ['street', 'city', 'country', 'state', 'zip']), _pick(new_values, ['street', 'city', 'country', 'state', 'zip']))) {
    return true;
  }
  return false;
}

export default {
  state_of_changes(original, new_values, requested_changes, documents) {
    if (requested_changes.document_for_address_required && requested_changes.document_for_dob_required && _compact(documents).length !== 2) {
      return 'missing_two_docs';
    }
    if ((requested_changes.document_for_address_required || requested_changes.document_for_dob_required) && _compact(documents).length === 0) {
      return 'missing_one_doc';
    }
    if (requested_changes.first_name_verification_required && original.first_name === new_values.first_name) {
      return 'changes_required';
    }
    if (requested_changes.last_name_verification_required && original.last_name === new_values.last_name) {
      return 'changes_required';
    }
    if (requested_changes.dob_verification_required && original.date_of_birth === new_values.date_of_birth) {
      return 'changes_required';
    }
    if (requested_changes.address_verification_required && !address_fields_changed(original, new_values)) {
      return 'changes_required';
    }

    return true;
  },
};
