module.exports = {
  error_view: {
    support: {
      default: 'If you need assistance, follow this link: <a target="_blank" href="https://www.hellocard.ca/support">https://www.hellocard.ca/support</a>.',
    },
    text: {
      default: 'Sorry, an unknown error occurred.',
      jwt_expired: 'Sorry, the link has expired.',
      jwt_invalid: 'Sorry, the link is invalid.',
    },
    title: {
      personal_info_change: 'Personal Information Change Request',
    },
  },
  errors: {
    http: {
      'invalid_change-request_state': 'You have already submitted your informations. Please reload this page.',
      jwt_verify_failed: 'JWT verification failed',
      personal_info_change_recaptcha_failed: 'Recaptcha is not valid. Please submit your form again.',
    },
    system: {
      system_bad_gateway: 'Could not reach the api for some reason',
      system_forbidden: 'Forbidden: {message}',
      system_resource_not_found: 'Not found',
      system_unauthorized: 'Unauthorized: {message}',
      validation_error: 'Validation Error: {message}',
    },
    systemError: 'SYSTEM ERROR: ',
  },
  footer: {
    app: 'App',
    cardholder_agreement: 'Cardholder Agreement',
    company: 'Company',
    copyright: '© 2020 HELLO Network Inc. All Rights Reserved',
    customer_service: 'Customer Service',
    description: '<b>Hello Visa* Prepaid Card</b> is issued by Peoples Trust Company pursuant to licence by Visa Int. *Trademark of Visa International Service Association and used under licence by Peoples Trust Company.',
    faq: 'FAQ',
    hello_card: 'Hello Card',
    how_it_works: 'How It Works',
    info: 'Support',
    legal: 'Legal',
    privacy_policy: 'Privacy Policy',
    terms: 'Terms and Conditions',
  },
  header: {
    hello_link: 'https://hellocard.ca/?lang=en',
  },
  pageNotFound: {
    message: 'The page you are trying to get does not exist!',
  },
  page_title: {
    error_view: 'Error',
    page_not_found: 'Page Not Found',
    personal_info_change_request_view: 'Change Personal Info',
  },
  pending_view: {
    change_request_id: 'Change Request Id',
    description: 'Wait for approval or rejection of your change request to edit your information again.',
    questions: 'Should any questions arise, please call our toll-free customer service at 1-833-65-HELLO.',
    title: 'You have a <span>pending</span> change request',
  },
  personal_info_change: {
    confirm: {
      changes_required: {
        button_cancel: 'NO, I WILL UPDATE MY INFORMATION',
        button_submit: 'YES, SUBMIT AS IS',
        paragraph1: 'You’ve been asked to verify your information and update if necessary. If you submit the form  as is, you may receive another link with instructions to upload one or two documents to complete your identity verification.',
        paragraph2: 'For more information about the identity verification process, please visit ',
        title: 'Are you sure?',
        url: 'www.hellocard.ca/verification?lang=en',
      },
      loading: {
        button_cancel: '',
        button_submit: '',
        paragraph1: '',
        paragraph2: '',
        title: '',
        url: '',
      },
      one_doc: {
        button_cancel: 'NO, I WILL UPLOAD THE REQUIRED DOCUMENT',
        button_submit: 'YES, SUBMIT AS IS',
        paragraph1: 'You’ve been asked to upload a document to complete your identity verification. If you submit the form as is, we will not be able to complete your identity verification process and issue your Hello card.',
        paragraph2: 'For more information about the identity verification process and to get the list of documents we can accept, please visit ',
        title: 'Are you sure?',
        url: 'www.hellocard.ca/verification?lang=en',
      },
      two_docs: {
        button_cancel: 'NO, I WILL UPLOAD THE REQUIRED DOCUMENTS',
        button_submit: 'YES, SUBMIT AS IS',
        paragraph1: 'You’ve been asked to upload two documents to complete your identity verification. If you submit the form as is, we will not be able to complete your identity verification process and issue your Hello card.',
        paragraph2: 'For more information about the identity verification process and to get the list of documents we can accept, please visit ',
        title: 'Are you sure?',
        url: 'www.hellocard.ca/verification?lang=en',
      },
    },
    description: 'Complete the form below and click Submit to update your personal information.',
    documents_section: {
      title: 'Documents',
      upload_document: 'Upload PDF, PNG, JPEG or JPG',
    },
    fields: {
      address: 'Residential Address',
      birthdate: 'Date of Birth',
      city: 'City',
      first_name: 'First Name',
      last_name: 'Last Name',
      state: 'Province',
      street: 'Street Address',
      zip: 'Postal Code',
    },
    heading: 'PERSONAL INFORMATION CHANGE REQUEST',
    submit_button: 'Submit',
  },
  processed_view: {
    change_request_id: 'Change Request Id',
    description: 'A new change request is required to edit your information again.',
    questions: 'Should any questions arise, please call our toll-free customer service at 1-833-65-HELLO.',
    title: 'The change request has been processed',
  },
  state: {
    ab: 'Alberta',
    bc: 'British Columbia',
    mb: 'Manitoba',
    nb: 'New Brunswick',
    nl: 'Newfoundland',
    ns: 'Nova Scotia',
    nt: 'Northwest Territories',
    nu: 'Nunavut',
    on: 'Ontario',
    pe: 'Prince Edward Island',
    qc: 'Quebec',
    sk: 'Saskatchewan',
    yt: 'Yukon',
  },
  success_view: {
    change_request_id: 'Change Request Id',
    description: 'We’ve received your updated personal information. We recommend you to keep your Change Request ID for future reference.',
    questions: 'Should any questions arise, please call our toll-free customer service at 1-833-65-HELLO.',
    title: 'Your personal information has been <span>successfully</span> submitted!',
  },
  validation: {
    custom: {
      date: {
        before: 'Must be above 18 years of age',
        date_format: 'Invalid format (YYYY-MM-DD)',
      },
      file: {
        file_size: 'File size should be less than {size} MB',
      },
      string: {
        length: {
          default: 'Must be {length} characters long',
          zip: 'Invalid format (XXX XXX)',
        },
      },
    },
  },
};
