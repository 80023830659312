import Vue from 'vue';

const service = {
  async deleteDocuments(payload) {
    const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/public/selfserve/v1/change-requests/application/documents/delete`, payload);
    return response.data.data;
  },
};

export default service;
