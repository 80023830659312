<template>
  <base-layout :fluid="false" id="scroll-top">
    <v-col>
      <error context="applicationView"></error>
    </v-col>
    <v-row v-if="showForm">
      <v-col class="join-header">
        <h2>{{ $t('personal_info_change.heading') }}</h2>
        <p>{{ $t('personal_info_change.description') }}</p>
      </v-col>
    </v-row>
    <personal-info-change-form v-if="showForm" :jwt-token="jwtToken" :application="application" @formSubmitted="showSuccessComponent()"></personal-info-change-form>
    <pending-view v-if="showPending" :change-request-id="change_request_id"></pending-view>
    <processed-view v-if="showProcessed" :change-request-id="change_request_id"></processed-view>
    <success-view v-if="showSuccess" :change-request-id="change_request_id"></success-view>
    <error-view v-if="showError" title="personal_info_change" :text="errorType"></error-view>
  </base-layout>
</template>

<script>
import { mapGetters } from 'vuex';
import { recaptcha } from '@/mixins';
import safeExecute from '@/composables/safe-execute';
import PersonalInfoChangeForm from '@/components/application/PersonalInfoChangeForm';
import PendingView from '@/components/application/PendingView';
import ProcessedView from '@/components/application/ProcessedView';
import SuccessView from '@/components/application/SuccessView';
import Error from '@/components/system/Error';

export default {
  components: { PersonalInfoChangeForm, PendingView, ProcessedView, SuccessView, Error },
  setup(props, { root }) {
    const { execute, working } = safeExecute(root.$store);
    return {
      execute,
      working,
    };
  },
  name: 'personal-info-change-request',
  title: (args) => args.$t('page_title.personal_info_change_request_view'),
  mixins: [recaptcha],
  props: {
    jwtToken: {
      type: String,
    },
  },
  data() {
    return {
      showForm: false,
      showPending: false,
      showProcessed: false,
      showSuccess: false,
      showError: false,
      errorType: '',
    };
  },
  computed: {
    ...mapGetters('ui', ['currentLocale']),
    ...mapGetters('application', ['application', 'change_request_id']),
  },
  watch: {
    currentLocale() {
      this.$title = this.$t('page_title.personal_info_change_request_view');
    },
    application(val) {
      if (val.status === 'active') {
        this.showForm = true;
        this.showPending = false;
        this.showProcessed = false;
      }
      if (val.status === 'pending') {
        this.showForm = false;
        this.showPending = true;
        this.showProcessed = false;
      }
      if (val.status === 'resolved') {
        this.showForm = false;
        this.showPending = false;
        this.showProcessed = true;
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      if (to.query.locale) {
        await vm.$store.dispatch('ui/changeLocale', to.query.locale);
      }

      try {
        const applicationValues = await vm.$store.dispatch('application/getApplicationValues', { token: to.params.jwtToken });
        if (applicationValues && applicationValues.status) {
          if (applicationValues.status === 'active') {
            vm.$data.showForm = true;
          }
          if (applicationValues.status === 'pending') {
            vm.$data.showPending = true;
          }
          if (applicationValues.status === 'resolved') {
            vm.$data.showProcessed = true;
          }
        }
      } catch (err) {
        let errorType = 'default';

        if (err.msg === 'jwt expired') {
          errorType = 'jwt_expired';
        }
        if (err.msg === 'invalid signature') {
          errorType = 'jwt_invalid';
        }

        vm.$data.errorType = errorType;
        vm.$data.showError = true;
      }

      next();
    });
  },
  mounted() {
    this.$vuetify.goTo('#scroll-top');
  },
  methods: {
    showSuccessComponent() {
      this.showSuccess = true;
      this.showForm = false;
    },
  },
};
</script>
