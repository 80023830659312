<template>
  <div class="language-switcher">
    <p @click="switchLocale()">{{ getOpposite() }}</p>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'language-switcher',
  data() {
    return {
      chosenLocale: this.locale,
      oppositeLocale: '',
    };
  },
  props: {
    locale: {
      type: String,
      required: true,
    },
  },
  watch: {
    chosenLocale(val) {
      this.changeLocale(val);
    },
    locale(val) {
      this.chosenLocale = val;
    },
  },
  computed: {
    ...mapGetters('ui', ['locales']),
  },
  methods: {
    ...mapActions('ui', ['changeLocale']),
    switchLocale() {
      const newLocale = this.getOpposite();
      this.changeLocale(newLocale);
    },
    getOpposite() {
      for (let locale of this.locales) {
        if (this.chosenLocale !== locale.value) {
          return locale.value;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import './../../scss/main.scss';

.language-switcher p {
  color: $header-text-color !important;
  text-transform: uppercase;
  margin-right: 10rem;
  padding: 20px;
  cursor: pointer;
  &:hover,
  &:focus {
    opacity: $text-hover-opacity;
  }
}

@media screen and (max-width: 960px) {
  .language-switcher p {
    margin-right: 2rem;
  }
}

@media screen and (max-width: 600px) {
  .language-switcher p {
    margin-right: 2px;
  }
}
</style>
